import React, { useState, useEffect } from 'react';
import logo from '../../assets/development.svg';
import AddAssetModal from '../../CRUD/addAsset';
import { Toast } from "flowbite-react";
import { HiFire } from "react-icons/hi";
import Table from '../../components/table';
import { database } from '../../firebase';
import { ref, onValue, remove } from 'firebase/database';
import DeleteModal from '../../components/DeleteModal';
import EditAsset from '../../CRUD/editAsset';
import ViewAsset from '../../CRUD/viewAsset';

const Assets = () => {
  const [toastMessage, setToastMessage] = useState('');
  const [assets, setAssets] = useState([]);
  const [assetToDelete, setAssetToDelete] = useState(null);
  const [isAddAssetModalVisible, setAddAssetModalVisible] = useState(false);
  const [isEditAssetVisible, setEditAssetVisible] = useState(false);
  const [assetToEdit, setAssetToEdit] = useState(null);
  const [toastDuration] = useState(500);
  const [selectedAsset, setSelectedAsset] = useState(null);
  
  // New state for ViewAsset modal visibility
  const [isViewAssetModalVisible, setIsViewAssetModalVisible] = useState(false);

  // New state for search query
  const [searchQuery, setSearchQuery] = useState('');

  // New state for selected filter
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [selectedLocationFilter, setSelectedLocationFilter] = useState('All');


  const closeAddAssetModal = () => {
    setAddAssetModalVisible(false);
    setSelectedAsset(null);
  };
  
  const closeEditAsset = () => {
    setEditAssetVisible(false);
    setAssetToEdit(null);
  };

  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

  const headers = ['Photo', 'Asset Name', 'Asset Code', 'Assigned to', 'Date Purchased', 'Asset Location', 'Asset Status'];

  const filteredAssets = assets.filter(asset => {
    const matchesSearch = asset.assetName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          asset.assetCode.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesFilter = (selectedFilter === 'All' || asset.assetStatus === selectedFilter) &&
                          (selectedLocationFilter === 'All' || asset.assetLocation === selectedLocationFilter);
    return matchesSearch && matchesFilter;
  });
  
  const displayData = filteredAssets.map(asset => ({
    assetName: asset.assetName,
    assetCode: asset.assetCode,
    assetAssignee: asset.assetAssignee,
    datePurchased: new Date(asset.datePurchased).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    }),
    assetLocation: asset.assetLocation,
    assetStatus: asset.assetStatus,
    imageUrl: asset.imageUrl,
    id: asset.id,
  }));

  useEffect(() => {
    const assetsRef = ref(database, 'Assets');
    const unsubscribe = onValue(assetsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const assetsArray = Object.keys(data).map((key) => ({
          id: key,
          assetName: data[key].assetName,
          assetCode: data[key].assetCode,
          assetAssignee: data[key].assetAssignee,
          datePurchased: data[key].datePurchased,
          assetLocation: data[key].assetLocation,
          assetStatus: data[key].assetStatus,
          imageUrl: data[key].imageUrl,
          assetDescription: data[key].assetDescription,
        }));
        setAssets(assetsArray);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleEdit = (item) => {
    setAssetToEdit(item);
    setEditAssetVisible(true);
  };

  const handleDeleteAsset = (itemId) => {
    const assetRef = ref(database, `Assets/${itemId}`);

    remove(assetRef)
      .then(() => {
        setAssets(prevAssets => prevAssets.filter(asset => asset.id !== itemId));
        setToastMessage('Asset deleted successfully!');
      })
      .catch((error) => {
        console.error('Error deleting asset from Firebase:', error);
        setToastMessage('Error deleting asset. Please try again.');
      });
  };

  const openDeleteModal = (asset) => {
    setAssetToDelete(asset);
    setDeleteModalVisible(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalVisible(false);
    setAssetToDelete(null);
  };

  const confirmDelete = () => {
    if (assetToDelete) {
      handleDeleteAsset(assetToDelete.id);
    }
    closeDeleteModal();
  };

  const handleToastClose = () => setToastMessage('');
  
  useEffect(() => {
    if (toastMessage) {
      const timer = setTimeout(() => {
        setToastMessage('');
      }, toastDuration);

      return () => clearTimeout(timer);
    }
  }, [toastMessage, toastDuration]);

  const handleAssetClick = (asset) => {
    setSelectedAsset(asset);
    setIsViewAssetModalVisible(true); 
  };

  return (
    <div className="">
      <div className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="w-full mb-1">
          <div className="sm:flex">
            <div className="items-center hidden mb-3 sm:flex sm:divide-x sm:divide-gray-100 sm:mb-0 dark:divide-gray-700">
              <form className="lg:pr-3">
                <label htmlFor="asset-search" className="sr-only">Search</label>
                <div className="relative mt-1 lg:w-64 xl:w-96">
                  <input
                    type="text"
                    id="asset-search"
                    className="bg-gray-50 border text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    placeholder="Search for asset name or asset code"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </form>
              <div className="flex pl-0 mt-3 space-x-1 sm:pl-2 sm:mt-0">

<select
  value={selectedFilter}
  onChange={(e) => setSelectedFilter(e.target.value)}
  className="bg-gray-50 border text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
>
  <option value="All">All</option>
  <option value="Active">Active</option>
  <option value="Inactive">Inactive</option>
  <option value="Archived">Assigned</option>
  <option value="Unassigned">Unassigned</option>
</select>


<select
  value={selectedLocationFilter}
  onChange={(e) => setSelectedLocationFilter(e.target.value)}
  className="bg-gray-50 border text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
>
  <option value="All">All Locations</option>
  <option value="Subic-FAD">Subic-FAD</option>
  <option value="Subic-Sales">Subic-Sales</option>
  <option value="Workshop">Workshop</option>
  <option value="Palawan">Palawan</option>
  <option value="Boracay">Boracay</option>
  <option value="Cebu">Cebu</option>
  <option value="Davao">Davao</option>
  <option value="Manila">Manila"</option>

</select>
</div>

            </div>
            <div className="flex items-center ml-auto space-x-2 sm:space-x-3">
              <button
                onClick={() => setAddAssetModalVisible(true)}
                type="button"
                className="inline-flex items-center justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                <svg
                  className="w-5 h-5 mr-2 -ml-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                    clipRule="evenodd"
                  />
                </svg>
                Add asset
              </button>
          
            </div>
        
          </div>
        </div>
      </div>

      <Table
        headers={headers}
        data={displayData}
        onAssetClick={handleAssetClick}
        onEdit={handleEdit}
        onDelete={openDeleteModal}
        totalItems={filteredAssets.length}
        itemsPerPage={10}
        onPageChange={(newPage) => console.log("Page changed to:", newPage)}
      />

      <AddAssetModal
        isVisible={isAddAssetModalVisible}
        onClose={closeAddAssetModal}
        selectedAsset={selectedAsset}
        onAssetAdded={() => {
          setToastMessage('Asset added successfully!');
          closeAddAssetModal();
        }}
      />

      <EditAsset
        isVisible={isEditAssetVisible}
        onClose={closeEditAsset}
        assetId={assetToEdit?.id}
        onAssetUpdated={() => {
          setToastMessage('Asset changes saved successfully!');
          closeEditAsset();
        }}
      />

      <DeleteModal
        isVisible={isDeleteModalVisible}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
        itemName={assetToDelete ? assetToDelete.assetName : ''}
      />

      {selectedAsset && isViewAssetModalVisible && (
        <ViewAsset
          asset={selectedAsset}
          isVisible={isViewAssetModalVisible}
          onClose={() => setIsViewAssetModalVisible(false)} 
        />
      )}

      {toastMessage && (
        <Toast className="fixed top-20 right-8">
          <div className="inline-flex h-8 w-8 items-center justify-center rounded-lg bg-blue-100 text-blue-800 dark:bg-blue-200 dark:text-blue-800">
            <HiFire />
          </div>
          <div className="ml-3 text-sm font-normal">{toastMessage}</div>
          <button onClick={handleToastClose} className="ml-2 w-4 h-4 text-gray-400 hover:text-gray-900 dark:hover:text-white">
            <span className="sr-only">Close</span>
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 14 14">
              <path
                fillRule="evenodd"
                d="M10.293 3.707a1 1 0 1 0-1.414-1.414L7 5.586 4.121 2.707a1 1 0 1 0-1.414 1.414L5.586 7l-3.879 3.879a1 1 0 1 0 1.414 1.414L7 8.414l2.879 2.879a1 1 0 1 0 1.414-1.414L8.414 7l3.879-3.879z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </Toast>
      )}
    </div>
  );
};

export default Assets;
