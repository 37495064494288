import React from 'react';
import Modal from '../components/Modal';
import { Link } from 'react-router-dom';

const ViewAsset = ({ asset, isVisible, onClose }) => {
  if (!asset) return null;

  const handleImageClick = (imageUrl) => {
    window.open(imageUrl, '_blank');
  };

  return (
    <Modal
      id="view-asset-modal"
      title="Asset Details"
      isVisible={isVisible}
      onClose={onClose}
      className="max-w-2xl w-full"  
    >
      <div className="flex w-full h-auto ">

        <Link
          className="absolute z-10 bottom-12 left-12 underline hover:text-primary-600 dark:hover:text-primary-400"
          onClick={() => handleImageClick(asset.imageUrl)}
        >
          View image
        </Link>
        <div className="w-1/2 mr-8"> 
          <img
            src={asset.imageUrl}
            alt={asset.assetName}
            className="w-full h-auto object-cover rounded-md hover:brightness-75"
          />
        </div>


        <div className="w-1/2 ">
          <div>
            <h3 className="text-base font-semibold text-gray-900 dark:text-gray-100">
              Asset Information
            </h3>

            <div className="mt-4 ">
              <dl className="divide-y divide-gray-100 dark:divide-gray-700 grid grid-cols-2 gap-4">
                {[ 
                  { label: 'Asset Name', value: asset.assetName },
                  { label: 'Asset Code', value: asset.assetCode },
                  { label: 'Date Purchased', value: asset.datePurchased },
                  { label: 'Assigned to', value: asset.assetAssignee },
                  { label: 'Asset Status', value: asset.assetStatus },
                  { label: 'Asset Location', value: asset.assetLocation },
                  { label: 'Asset Remarks', value: asset.assetRemarks },
                  { label: 'Asset Description', value: asset.Description },
                ].map(({ label, value }) => (
                  <div key={label} className="py-2">
                    <dt className="text-sm font-medium text-gray-900 dark:text-gray-100 mr-4">
                      {label}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-700 dark:text-gray-300 mr-4 break-words">
                      {value}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewAsset;
