import React, { useEffect, useState } from 'react';
import Modal from '../components/Modal'; 
import EditForm from '../pages/Asset Inventory/EditForm';
import { database } from '../firebase';
import { ref, update, onValue } from 'firebase/database';

const EditAsset = ({ isVisible, onClose, assetId, onAssetUpdated }) => {
  const [formData, setFormData] = useState({
    assetType: '',
    assetLocation: '',
    assetAssignee: '',
    assetStatus: '',
    assetName: '',
    assetCode: '',
    datePurchased: '',
    assetRemarks: '',
    assetDescription: '',
  });
  
  const [loading, setLoading] = useState(true); 
  const [users, setUsers] = useState([]); 

  
  useEffect(() => {
    const assetRef = ref(database, `Assets/${assetId}`);
    const usersRef = ref(database, 'users'); 

  
    const assetListener = onValue(assetRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setFormData(data); 
      }
      setLoading(false); 
    });

 
    const usersListener = onValue(usersRef, (snapshot) => {
      const usersData = snapshot.val();
      if (usersData) {
        const usersList = Object.keys(usersData).map(key => ({
          label: `${usersData[key].firstName} ${usersData[key].lastName}`,
          value: key,
        }));
        setUsers(usersList);
      }
    });

    
    return () => {
      assetListener(); 
      usersListener();
    };
  }, [assetId]); 

  const fields = [
    { label: 'Asset Name', name: 'assetName', placeholder: 'Enter Asset Name', type: 'text', colSpan: 3, required: true },
    { label: 'Asset Code', name: 'assetCode', placeholder: 'Enter Asset Code', type: 'text', colSpan: 3, required: true },
    { label: 'Asset Type', name: 'assetType', placeholder: 'Choose Asset Type', type: 'select', colSpan: 3, required: true, options: 
      [ { label: 'Tools and Machines', value: 'TM'}, { label: 'Fixed Furniture & Office Equipment', value: 'FFOE'},] },
    { label: 'Date Purchased', name: 'datePurchased', placeholder: 'Add Date Purchased', type: 'date', colSpan: 3, required: false},
    { label: 'Asset Location (Branch)', name: 'assetLocation', placeholder: 'Choose Branch', type: 'select', colSpan: 3, required: true, options:
       [ { label: 'Select Branch', value: '' }, { label: 'Subic-FAD', value: 'Subic-FAD'}, { label: 'Subic-Sales', value: 'Subic-Sales'}, { label: 'Workshop', value: 'Workshop'}, { label: 'Manila', value: 'Manila' }, { label: 'Cebu', value: 'Cebu' }, { label: 'Davao', value: 'Davao' }, { label: 'Boracay', value: 'Boracay' }, { label: 'Palawan', value: 'Palawan' },  ] },
    { label: 'Assigned to:', name: 'assetAssignee', placeholder: 'Choose Asset Assignee', colSpan: 3, type: 'select',  options: [
      { label: 'Branch', value: 'Branch' },
      ...users 
    ]},  
    { label: 'Asset Status', name: 'assetStatus', placeholder: 'Choose Asset Status', colSpan: 3, type: 'select', required: true, options: 
      [ { label: 'Active', value: 'Active'}, { label: 'Inactive', value: 'Inactive'}, { label: 'Archived', value: 'Archived'}, { label: 'Lost', value: 'Lost'},] },
    { label: 'Asset Remarks', name: 'assetRemarks', placeholder: 'Add Asset Remarks', colSpan: 3, type: 'text' },
    { label: 'Asset Description', name: 'assetDescription', placeholder: 'Add Asset Description', colSpan: 6, type: 'text' },
  ];

  const handleSubmit = async (updatedFormData) => {
    const updatedFormDataWithAssignee = {
      ...updatedFormData,
      assetAssignee: updatedFormData.assetAssignee === 'Branch' ? 'Branch' : updatedFormData.assetAssignee,
    };

    const assetRef = ref(database, `Assets/${assetId}`);
    try {
      await update(assetRef, updatedFormDataWithAssignee);
      onAssetUpdated(); 
      onClose(); 
    } catch (error) {
      console.error('Error updating asset:', error);
    }
  };

  return (
    <Modal id="edit-asset-modal" title="Edit Asset" isVisible={isVisible} onClose={onClose}>
      {loading ? (
        <div>Loading...</div> 
      ) : (
        <EditForm 
          fields={fields} 
          buttonText="Save Changes" 
          formData={formData} 
          setFormData={setFormData} 
          onSubmit={handleSubmit} 
        />
      )}
    </Modal>
  );
};

export default EditAsset;
