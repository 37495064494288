import React, { useState, useEffect, useRef } from 'react';
import Modal from '../components/Modal';
import Form from '../components/Form';
import { database, storage } from '../firebase';
import { ref, set, get, onValue } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Toast } from "flowbite-react"; 
import { HiFire } from "react-icons/hi"; 
import '../styles/loading.css';

const AddAssetModal = ({ isVisible, onClose, onAssetAdded }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [formData, setFormData] = useState({
    assetType: '',
    assetLocation: '',
    assetAssignee: '',
    assetStatus: '',
    assetName: '',
    assetCode: '',
    datePurchased: '',
    assetRemarks: '',
    assetDescription: '',
  });
  const [showToast, setShowToast] = useState(false); 
  const [users, setUsers] = useState([]); 
  const fileInputRef = useRef(null); 
  const [lastAssetNumbers, setLastAssetNumbers] = useState({ TM: 100, FOE: 100 }); // Start from 100

  useEffect(() => {
    // Fetch users from Firebase when the modal is opened
    const fetchUsers = () => {
      const usersRef = ref(database, 'users'); 
      onValue(usersRef, (snapshot) => {
        const userList = [];
        snapshot.forEach((childSnapshot) => {
          const userData = childSnapshot.val();
          userList.push({ uid: childSnapshot.key, ...userData });
        });
        setUsers(userList);
      });
    };

    if (isVisible) {
      fetchUsers();
    }
  }, [isVisible]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const generateAssetCode = (type) => {
    const prefixMap = {
      TM: 'TM',
      FFOE: 'FFOE',
    };
    const prefix = prefixMap[type] || '';
    
    const lastAssetNumber = lastAssetNumbers[type]; // Get the last asset number for the selected type
    const newAssetNumber = lastAssetNumber + 1; // Increment by 1
    
    // Update the last asset number state for the selected type
    setLastAssetNumbers((prev) => ({
      ...prev,
      [type]: newAssetNumber, // Update to the new incremented number
    }));

    return `${prefix}${newAssetNumber.toString().padStart(3, '0')}`; // Format: TM001, FFOE001
  };

  const handleAssetTypeChange = (event) => {
    const selectedType = event.target.value;
    const generatedCode = generateAssetCode(selectedType);
    setFormData((prev) => ({
      ...prev,
      assetType: selectedType,
      assetCode: generatedCode,
    }));
  };

  const fields = [
    { label: 'Asset Name', name: 'assetName', placeholder: 'Enter Asset Name', type: 'text', colSpan: 3, required: true },
    { label: 'Asset Type', name: 'assetType', placeholder: 'Choose Asset Type', type: 'select', colSpan: 3, required: true, options: 
      [ { label: 'Tools and Machines', value: 'TM'}, { label: 'Fixed Furniture & Office Equipment', value: 'FFOE'},] },
    { label: 'Asset Code', name: 'assetCode', placeholder: 'Generated Asset Code', type: 'text', colSpan: 3, required: true, readOnly: true }, // Make it read-only
    { label: 'Date Purchased', name: 'datePurchased', placeholder: 'Add Date Purchased', type: 'date', colSpan: 3, required: false },
    { label: 'Asset Location (Branch)', name: 'assetLocation', placeholder: 'Choose Branch', type: 'select', colSpan: 3, required: true, options:
       [ { label: 'Select Branch', value: '' }, { label: 'Subic-FAD', value: 'Subic-FAD'}, { label: 'Subic-Sales', value: 'Subic-Sales'}, { label: 'Workshop', value: 'Workshop'}, { label: 'Manila', value: 'Manila' }, { label: 'Cebu', value: 'Cebu' },{ label: 'Davao', value: 'Davao' }, { label: 'Boracay', value: 'Boracay' }, { label: 'Palawan', value: 'Palawan' },  ] },
       { 
        label: 'Assigned to:', 
        name: 'assetAssignee', 
        placeholder: 'Choose Asset Assignee', 
        type: 'select', 
        colSpan: 3, 
        required: false, 
        options: [
          { label: 'Branch', value: 'Branch' }, 
          ...users.map(user => ({ label: `${user.firstName} ${user.lastName}`, value: user.uid })) // Use the spread operator to merge user options
        ]
      },
    { label: 'Asset Status', name: 'assetStatus', placeholder: 'Choose Asset Status', type: 'select', colSpan: 3, required: true,
       options: [ { label: 'Active', value: 'Active'}, { label: 'Inactive', value: 'Inactive'}, { label: 'Archived', value: 'Archived'}, { label: 'Lost', value: 'Lost'},] },
    { label: 'Asset Remarks', name: 'assetRemarks', placeholder: 'Add Asset Remarks', type: 'text', colSpan: 3 },
    { label: 'Asset Description', name: 'assetDescription', placeholder: 'Add Asset Description', type: 'text' },
  ];

  const handleSubmit = async (formData) => {
    try {
        setUploading(true);

        let imageUrl = '';
        if (file) {
            const fileRef = storageRef(storage, `Assets/${Date.now()}_${file.name}`);
            const snapshot = await uploadBytes(fileRef, file);
            imageUrl = await getDownloadURL(snapshot.ref);
        }

        // Create the new asset data to save to Firebase
        const newAssetData = {
            ...formData,
            imageUrl,
            dateAssigned: new Date().toISOString(), // Set the current date as the date assigned
        };

        const newAssetRef = ref(database, 'Assets/' + Date.now());
        await set(newAssetRef, newAssetData);

        console.log('Asset added successfully!');

        // Update the user's assignedAssets array directly
        if (formData.assetAssignee) {
            const userRef = ref(database, `users/${formData.assetAssignee}`);
            const userSnapshot = await get(userRef);
            const userData = userSnapshot.val();

            if (userData) {
                const updatedAssignedAssets = [
                    ...(userData.assignedAssets || []),
                    { id: newAssetRef.key, dateAssigned: newAssetData.dateAssigned },
                ];

                await set(userRef, {
                    ...userData,
                    assignedAssets: updatedAssignedAssets,
                });
            }
        }

        onAssetAdded();

        // Clear the file input and form data, and reset state
        setFile(null);
        setFormData({
            assetType: '',
            assetLocation: '',
            assetAssignee: '',
            assetStatus: '',
            assetName: '',
            assetCode: '',
            datePurchased: '',
            assetRemarks: '',
            assetDescription: ''
        });

        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Clear the file input value
        }

        onClose();
        setShowToast(true); // Show success toast
    } catch (error) {
        console.error('Error adding asset:', error);
    } finally {
        setUploading(false);
    }
};

  return (
    <Modal id="add-asset-modal" title="Add New Asset" isVisible={isVisible} onClose={onClose}>
      <input
        ref={fileInputRef}
        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
        id="file-upload"
        type="file"
        onChange={handleFileChange}
      />
      
      {uploading && 
        <div className="loader">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M64 32C28.7 32 0 60.7 0 96v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm280 72a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm-40 96a24 24 0 0 1 24-24h80a24 24 0 0 1 0 48h-80a24 24 0 0 1-24-24zm-80-24a24 24 0 0 1 0 48H64a24 24 0 0 1 0-48h160zm40 128a24 24 0 1 1 0 48h-80a24 24 0 0 1 0-48h80zm-160 0a24 24 0 1 1 0 48H64a24 24 0 0 1 0-48h80zM256 368a24 24 0 0 1 24-24h80a24 24 0 0 1 0 48h-80a24 24 0 0 1-24-24zm0-72a24 24 0 0 1 24-24h80a24 24 0 0 1 0 48h-80a24 24 0 0 1-24-24zm-128-72a24 24 0 0 1 0 48H64a24 24 0 0 1 0-48h64zm248 288c0 8.6-1.1 17.4-3.3 25.8 0 0-7.4 0-8.4 0-.1-.4-.3-.9-.3-1.5 0-19.5-15.8-35.3-35.3-35.3-19.5 0-35.3 15.8-35.3 35.3 0 8.1-1.6 16.1-4.3 23.2C261.5 464.8 208 488 208 512c0 2.2 0 4.4.2 6.6C190.6 514.4 176 493.4 176 469.5c0-46.3 33.9-83.5 76-92.4C290.7 371 288 359.8 288 348.6c0-39.5 33.2-73.1 73.2-73.1 39.8 0 73.1 33.2 73.1 73.1 0 22.6-17.1 40.8-38.9 43.5-5.8-6.3-13.2-11.7-22.6-16.2z"/></svg>

        </div>
      }
      <Form fields={fields} formData={formData} setFormData={setFormData} onSubmit={handleSubmit} />
      
    </Modal>
  );
};

export default AddAssetModal;
