import React, { useEffect, useRef } from 'react';
import { ref, set, get } from 'firebase/database';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { database, auth } from '../../firebase';

const Register = () => {
  const email = 'enrique.lumbang@broadwatermarine.com';
  const password = 'Broadwater2024';
  const userCreatedRef = useRef(false); 

  useEffect(() => {
    const userData = {
      firstName: 'Enrique',
      lastName: 'Lumbang',
      phoneNumber: '09453503877',
      dateOfBirth: '1992-02-15',
      hireDate: '2024-02-01',
      department: 'Finance and Admin Department',
      position: 'Accounting Associate',
      salary: '70000',
      address: 'Olongapo',
      status: 'Active',
      role: 'IT Admin'
    };

    const addUser = async () => {
      if (userCreatedRef.current) return;

      try {
        const userRef = ref(database, 'users');
        const snapshot = await get(userRef);
        
        const existingUser = snapshot.val() && Object.values(snapshot.val()).some(user => user.email === email);
        
        if (existingUser) {
          console.log('User already exists in the database, skipping creation.');
          return;
        }

        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        console.log('Authenticated User:', auth.currentUser); 

        await set(ref(database, 'users/' + user.uid), {
          email: user.email,
          ...userData,
        });

        console.log('User created successfully:', user);
        console.log('User Role:', userData.role);
        userCreatedRef.current = true; 
      } catch (error) {
        if (error.code === 'auth/email-already-in-use') {
          console.error('Email already in use in Firebase Authentication. Skipping creation.');
        } else {
          console.error('Error during user registration:', error);
        }
      }
    };

    addUser();

  }, []); 

  return (
    <div>
      <h1>User Registration</h1>
      <p>Check the console for registration status and role.</p>
    </div>
  );
};

export default Register;
